<template>
  <div class="bg-white">
    <div class="mx-auto max-w-2xl px-4 py-8 sm:px-6 sm:py-12 lg:max-w-7xl lg:px-8">
      <div v-if="pagination && searched_by" class="md:flex mb-16">
        <span class="text-sm text-gray-900 font-bold">検索条件：　</span><span class="text-sm text-gray-900">{{ searched_by }}</span>
      </div>

      <div class="md:flex md:items-center md:justify-center">
        <h2 class="text-2xl font-bold tracking-tight text-gray-500">{{ title }}</h2>
      </div>

      <div class="mt-6 grid grid-cols-2 gap-x-1 gap-y-10 sm:gap-x-1 md:grid-cols-5 md:gap-y-0 lg:gap-x-1">
        <div v-for="item in pageinfo.items.slice(0, pageLimit)" :key="item.id" class="group relative mt-4 border border-gray-300">
          <div class="h-32 w-full overflow-hidden rounded-md group-hover:opacity-75 lg:h-32 xl:h-48">
            <img :src="(item.image_url) ? `${runtimeConfig.public.imgBase}${item.image_url}` : '/assets/noimage-760x460.png'"
              :alt="item.title" class="h-full w-full object-cover object-center" />
          </div>
          <!-- <div class="-mt-8 flex justify-end mr-2">
            <div :class="[isFavorite(item) ? 'text-pink-500' : 'text-gray-400']"><HeartIcon class="w-6 h-6"></HeartIcon></div>
          </div> -->
          <div class="flex flex-row m-2">
            <div class="grow">
              <h3 class="text-sm text-gray-700">
                <NuxtLink :to="`/${target}/${item.id}`">
                  <span aria-hidden="true" class="absolute inset-0" />
                  <span>{{ item.id }}:</span> {{ item.title }}
                </NuxtLink>
              </h3>
              <p class="text-sm text-gray-500">{{ Number(item.production_cost) ? Number(item.production_cost).toLocaleString('ja-JP', {style:'currency', currency: 'JPY'}) : '' }}</p>
            </div>
            <div :class="[isFavorite(item) ? 'mt-2 text-pink-500 inline-block' : 'mt-2 text-gray-400 inline-block']"><HeartIcon class="w-6 h-6"></HeartIcon></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="pagination">
    <PaginateButtons 
      :current-page="pageinfo.page"
      :total-page-count="pageinfo.totalPages"
      :current-items-count="pageinfo.currentItems"
      :total-items-count="pageinfo.totalItems"
      :page-limit="pageinfo.limit"
      :clickHandler="switchPageHandler">
    ></PaginateButtons>
  </div>
</template>
  
<script setup>
import { HeartIcon } from '@heroicons/vue/24/outline';

const favoriteStore = useFavoriteStore()
const runtimeConfig = useRuntimeConfig()

const props = defineProps({
  title: { type: String, default: 'アイテム一覧' },
  target: { type: String, default: 'products' },
  conditions: { type: Object, default: {} },
  pagination: { type: Boolean, default: false },
  currentPage: { type: Number, default: 0 },
  currentItemsCount: { type: Number, default: 0 },
  totalItemsCount: { type: Number, default: 0 },
  totalPageCount: { type: Number, default: 0 },
  clickHandler: { type: Function, default: () => {} },
  pageRange: { type: Number, default: 3 },
  pageLimit: { type: Number, default: 20 },
  marginPages: { type: Number, default: 1 },
  pageinfo: { type: Object, default: {} },
  switchPageHandler: {type: Function, default: ()=>{} },
});

const isFavorite = (item) => {
  return favoriteStore.isFavorite(props.target, item.id)
}

const searched_by = computed(() => {
  const keys = {
    keywords: 'キーワード',
    categories: 'カテゴリー',
    creators: 'クリエイター',
    cloths: '生地',
  }
  let str = ''
  for (const [key, values] of Object.entries(props.conditions)) {
    //str += values.map(v => `${keys[key]}：${v}`).join(',　') + ',　'
  }
  return str
})
</script>